<template>
  <div>
    <top-fixed></top-fixed>

    <div
      style="
        background-color: #1d76fb;
        width: 7.5rem;
        height: 7.5rem;
        color: #fff;
        border-radius: 0 0 10px 10px;
      "
    >
      <div style="display: flex; justify-content: center; align-items: center">
        <img style="width: 3.5rem" src="../assets/img/acooutbg.png" alt="" />
      </div>
      <div>
        <div style="text-align: center">{{ $t("hj2") }}(USDT)</div>
        <div style="text-align: center">45,880.00</div>
      </div>

      <div
        style="display: flex; padding: 0.25rem; justify-content: space-between"
      >
        <div style="text-align: center">
          <div>{{ $t("hj3") }}(ETH)</div>
          <div>0</div>
        </div>
        <div style="text-align: center">
          <div>{{ $t("hj3") }}(ETH)</div>
          <div>0</div>
        </div>
        <div style="text-align: center">
          <div>{{ $t("hj4") }}(ETH)</div>
          <div>0</div>
        </div>
      </div>

      <div>{{ $t("hj5") }}</div>
    </div>

    <div style="" v-if="type == 1">
      <div>
        <div>{{ $t("hj6") }}</div>
        <div>
          <img src="../assets/img/兑换-@3x.png" alt="" />
          <span>{{ $t("hj7") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TopFixed from "../components/TopFixed.vue";
export default {
  components: { TopFixed },
  name: "AccountView",
  data () {
    return {
      type: 1
    }

  },
  mounted () {
    /* eslint-disable no-new */
  },
};
</script>
